<template>
    <div class="pharmacy-information-card">
        <div class="pharmacy-information-card__header">
            <QuestionIcon class="mr--sm--3"/>
            <span>Thông tin Boston Pharma</span>
        </div>
        <div class="pharmacy-information-card__content">
            <div class="mb--sm--4 mb--md--6">
                <a class="pharmacy-information-card__action d--sm--flex align-items--sm--center" href="mailto:support@bostonpharma.com.vn" title="">
                    <EnvelopIcon class="mr--sm--3"/>
                    <span>support@bostonpharma.com.vn</span>
                </a>
            </div>
            <div class="mb--sm--4 mb--md--6">
                <a class="pharmacy-information-card__action d--sm--flex align-items--sm--center" href="https://www.facebook.com/BostonVN/" title="" target="_blank">
                    <FacebookIcon class="mr--sm--3"/>
                    <span>Boston Pharma</span>
                </a>
            </div>
            <div>
                <a class="pharmacy-information-card__action d--sm--flex align-items--sm--center" href="https://bostonpharma.com.vn" title="" target="_blank">
                    <WebsiteIcon class="mr--sm--3"/>
                    <span>https://bostonpharma.com.vn</span>
                </a>
            </div>
        </div>
    </div>
</template>

<script>
import QuestionIcon from '@/assets/question.svg'
import EnvelopIcon from '@/assets/envelop-icon.svg'
import FacebookIcon from '@/assets/facebook-icon.svg'
import WebsiteIcon from '@/assets/website-icon.svg'
export default {
  components: {
    QuestionIcon,
    EnvelopIcon,
    FacebookIcon,
    WebsiteIcon
  },
  data () {
    return {}
  }
}
</script>

<style lang="scss">
    @import "@/scss/respoinsive";
    .pharmacy-information-card {
        &__header {
            display: flex;
            align-items: center;
            padding: 16px;
            border-bottom: 1px solid #59C6BC;
            font-size: 24px;
            span {
                text-overflow: ellipsis;
                white-space: nowrap;
                width: calc(100% - 52px);
                display: block;
                overflow: hidden;
            }
        }
        &__content {
            padding: 16px;
            overflow-x: auto;
            @include tablet() {
                padding: 24px 52px;
            }
        }

        &__action {
            display: flex;
            align-items: center;
            color: #003B35;
            text-decoration: underline;
            transition: all 0.3s;
            font-size: 18px;
            &:hover {
                color: #003B35;
                text-decoration: underline;
                opacity: 0.75;
            }
        }
    }
</style>
