<template>
  <div class="page pharmacy-information-page">
    <Header />
    <div class="container">
      <div class="h5 font-weight--sm--400 text--black mb--sm--12 text--sm--center">Thông tin Boston Pharma</div>
      <PharmacyInformationCard />
    </div>
    <Footer/>
    <FooterMobile/>
  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import PharmacyInformationCard from '@/components/PharmacyInformationCard.vue'
import FooterMobile from '@/components/FooterMobile.vue'

export default {
  name: 'PharmacyInformation',
  components: {
    Header,
    Footer,
    FooterMobile,
    PharmacyInformationCard
  },
  data () {
    return {
      msg: 'Welcome to Your Vue.js App'
    }
  },
  mounted () {
    this.$store.dispatch('setHeaderMobile', {
      heading: true,
      headingText: 'Thông tin Boston Pharma',
      back: true,
      search: false,
      notify: true,
      cart: true
    })
    this.$store.dispatch('setFooterMobile', true)
  }
}
</script>
